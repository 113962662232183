import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Home from '@material-ui/icons/Home';
import Business from '@material-ui/icons/Business';
import Weekend from '@material-ui/icons/Weekend';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import pricingPageStyle from 'assets/jss/material-dashboard-pro-react/views/pricingPageStyle';

// core components
import AuthHeader from "dashboard-pro-child/AuthHeader";
import Footer from "dashboard-pro-child/Footer";

import bgImage from 'localAssets/images/pricing-background.jpg';

import styles from '../app/routes/auth/Auth.module.scss';
import pricingStyles from './pricing.module.scss';

import { mergeClasses } from 'utilities/functions.tsx';

import './pricing-styles.css';

function setLocalStorage(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch(e) {
    console.log(e);
  }
}

const PricingPage = ({ classes }) => {
  function goToSignup() {
    navigate('/app/auth/sign-up');
  }

  function getButtonClickFunc(billingOption) {
    return function() {
      setLocalStorage('returnUrl', '/app/billing');
      setLocalStorage('billingOption', billingOption);
  
      goToSignup();
    }
  }

  return (
    <div
      className={styles.wrap}
      style={{
        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(" + bgImage + ")",
        backgroundSize: 'cover'
    }}>
      <Helmet>
        <title>Pricing - Replaix</title>
      </Helmet>
      <AuthHeader/>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <h2 className={classes.title}>Pick the best plan for you</h2>
            <h5 className={mergeClasses(classes.description, pricingStyles.header)}>
              Each package gives you access to the best Open Escrow tool.
            </h5>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card pricing plain className={pricingStyles.transparentCard}>
              <CardBody pricing plain>
                <h6 className={mergeClasses(classes.cardCategory, pricingStyles.cardCategory)}>
                  Standard
                </h6>
                <div className={classes.icon}>
                  <Weekend className={classes.iconWhite}/>
                </div>
                <h3
                  className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
                >
                  FREE
                </h3>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>Open Escrow-Purchase</p>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>Open Escrow-Refinance</p>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>Cloud Storage</p>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>Email/Save/Print</p>
                <Button round color="white" onClick={goToSignup}>
                  Choose plan
                </Button>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card pricing raised>
              <CardBody pricing>
                <h6 className={mergeClasses(classes.cardCategory, pricingStyles.cardCategory)}>Annual <span>(25% Savings)</span></h6>
                <div className={classes.icon}>
                  <Home className={classes.iconRose} />
                </div>
                <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  $15/month<br/>Billed Annually
                </h3>
                <p className={pricingStyles.lineItem}>Open Escrow-Purchase</p>
                <p className={pricingStyles.lineItem}>Open Escrow-Refinance</p>
                <p className={pricingStyles.lineItem}>Cloud Storage</p>
                <p className={pricingStyles.lineItem}>Email/Save/Print</p>
                <p className={pricingStyles.lineItem}>* Scheduled email marketing &ndash; set it and forget it</p>
                <Button round color="rose" onClick={getButtonClickFunc('annual')}>
                  Choose plan
                </Button>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card pricing plain className={pricingStyles.transparentCard}>
              <CardBody pricing plain>
              <h6 className={mergeClasses(classes.cardCategory, pricingStyles.cardCategory)}>Monthly</h6>
                <div className={classes.icon}>
                  <Business className={classes.iconWhite} />
                </div>
                <h3 className={`${classes.cardTitleWhite} ${classes.marginTop30}`}>
                  $20/month
                </h3>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>Open Escrow-Purchase</p>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>Open Escrow-Refinance</p>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>Cloud Storage</p>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>Email/Save/Print</p>
                <p className={mergeClasses(pricingStyles.lineItem, pricingStyles.white)}>* Scheduled email marketing &ndash; set it and forget it</p>
                <Button round color="white" onClick={getButtonClickFunc('monthly')}>
                  Choose plan
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <Footer white />
    </div>
  );
}

PricingPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pricingPageStyle)(PricingPage);